import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, CanActivateChild, Router, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from './auth.service';
import { map, take, tap } from 'rxjs/operators';
import { User } from '../models/user.model';
import { RouteGuardService } from './route-guard.service';

@Injectable()
export class AuthGuard implements CanActivate, CanActivateChild {
	userEntity;
	constructor(private _router: Router, private auth: AuthService, private router: Router, private routeGuardService: RouteGuardService) {}

	canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | boolean {
		return this.auth.user.pipe(
			take(1),
			map((user) => !!user),
			tap((loggedIn) => {
				if (!loggedIn) {
					this._router.navigate(['/auth/login']);
				}
			})
		);
	}

	canActivateChild(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | boolean {
		return this.routeGuardService.canNavigateTo(next.routeConfig.path).pipe(
			map((val) => !!val),
			tap((canDirect) => {
				if (!canDirect) {
					this._router.navigate(['/pages/planner/dashboard']);
				} else {
					return true;
				}
			})
		);
	}
}
