import { Component } from '@angular/core';

@Component({
	selector: 'fuse-demo-content',
	templateUrl: './demo-content.component.html',
	styleUrls: ['./demo-content.component.scss'],
})
export class FuseDemoContentComponent {
	/**
	 * Constructor
	 */
	constructor() {}
}
