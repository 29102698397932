import { Component, ViewEncapsulation, OnInit, EventEmitter } from '@angular/core';
import { fuseAnimations } from '@fuse/animations';

@Component({
	selector: 'send-message-dialog',
	styleUrls: [],
	encapsulation: ViewEncapsulation.None,
	animations: fuseAnimations,
	template: `
		<h2 mat-dialog-title>Sent Message To Crew</h2>
		<mat-dialog-content class="mat-typography">
			<p>
				<mat-form-field appearance="outline" fxFlex="100" class="pr-4">
					<mat-label>Message</mat-label>
					<textarea matInput name="message" [(ngModel)]="message" maxlength="160" rows="3" cols="40"> </textarea>
					<mat-error>Message is required!</mat-error>
				</mat-form-field>
			</p>
			<p>{{ 0 + message.length }}/160</p>
		</mat-dialog-content>
		<mat-dialog-actions align="end">
			<button mat-button mat-dialog-close>Cancel</button>
			<button mat-button class="accent-bg accent" [mat-dialog-close]="true" [disabled]="!message" (click)="sendSMS()">Send</button>
		</mat-dialog-actions>
	`,
})
export class SendMessageDialog implements OnInit {
	sendingSms = false;
	message = '';
	onAdd = new EventEmitter();

	constructor() {}

	ngOnInit() {}

	sendSMS() {
		this.onAdd.emit(this.message);
	}
}
