import { Component, OnInit, Inject, ViewEncapsulation } from '@angular/core';
import { MatDialogRef, MatDialog, MAT_DIALOG_DATA } from '@angular/material';
import { FuseConfirmDialogComponent } from '@fuse/components/confirm-dialog/confirm-dialog.component';
import { AngularFirestore } from '@angular/fire/firestore';
import { AngularFireStorage, AngularFireUploadTask } from '@angular/fire/storage';
import { ImagesProvider } from '../images';
import { FuseProgressBarService } from '@fuse/components/progress-bar/progress-bar.service';
import { User } from '../../../models/user.model';
import { finalize } from 'rxjs/operators';
import { ProfileComponent } from '../profile.component';

@Component({
	selector: 'app-imageUploadDialog',
	templateUrl: './imageUploadDialog.component.html',
	styleUrls: ['./imageUploadDialog.component.scss'],
	encapsulation: ViewEncapsulation.None,
})
export class ImageUploadDialogComponent implements OnInit {
	confirmDialogRef: MatDialogRef<FuseConfirmDialogComponent>;
	task: AngularFireUploadTask;
	uploadProgress: any;
	croppedImg: any = '';
	user: User;
	imgSelected = false;
	imageEvent: any;
	uploadingImg = false;
	/**
	 * Constructor
	 *
	 * @param {MatDialogRef<ImageUploadDialogComponent>} matDialogRef
	 * @param _data
	 * @param {MatDialog} _matDialog
	 */
	constructor(
		public matDialogRef: MatDialogRef<ImageUploadDialogComponent>,
		@Inject(MAT_DIALOG_DATA) private _data: any,
		private _matDialog: MatDialog,
		public afStore: AngularFirestore,
		private storage: AngularFireStorage,
		private imagesProvider: ImagesProvider,
		private _fuseProgressBarService: FuseProgressBarService
	) {}

	ngOnInit() {
		this.user = this._data.user;
	}

	displayImage(event) {
		this.imageEvent = event;
		this.imgSelected = true;
		let reader = new FileReader();
		reader.readAsDataURL(event.target.files[0]);
		reader.onerror = (error) => {
			error;
		};
		reader.onload = () => {
			this.croppedImg = reader.result;
		};
	}

	cancelImage() {
		this.croppedImg = '';
	}

	// Entity template uploads
	uploadProfileImage() {
		this.uploadingImg = true;
		this._fuseProgressBarService.show();

		const imageType = `${this.user.firstname} ${this.user.lastname}`;
		const fileName = `${new Date().getTime()}_${imageType.toString().toLowerCase()}.png`;
		const path = `users/${this.user.id}/images/${fileName}`;
		this.task = this.storage.upload(path, this.imageEvent.target.files[0]);
		const ref = this.storage.ref(path);

		let reader = new FileReader();
		reader.readAsDataURL(this.imageEvent.target.files[0]);
		reader.onerror = (error) => {
			error;
		};
		return (reader.onload = () => {
			return new Promise((resolve, reject) => {
				this.uploadProgress = this.task.percentageChanges();
				this._fuseProgressBarService.setValue(this.uploadProgress);
				this.task
					.snapshotChanges()
					.pipe(
						finalize(() => {
							const downloadURL = ref.getDownloadURL();
							downloadURL.subscribe((url) => {
								this.imagesProvider.generateFromImage(reader.result, 100, 100, 0.8, (smallImageData) => {
									const data = { photoURL: url, photoURLThumbnail: smallImageData };
									this.user.photoURL = smallImageData;
									this.saveDataToFirestore(this.user.id, data)
										.then(() => {
											return resolve();
										})
										.catch((err) => {
											return reject('Failed to save data to firestore: ' + err);
										});
								});
							});
						})
					)
					.subscribe();
			});
		});
	}

	close() {
		this._matDialog.closeAll();
	}

	private saveDataToFirestore(userId, downloadData) {
		const userRef = this.afStore.doc(`users/${userId}`);

		return userRef.update(downloadData).then(() => {
			this._fuseProgressBarService.hide();
			this._matDialog.closeAll();
			this.uploadingImg = false;
		});
	}
}
