import { FuseNavigation } from '@fuse/types';

export const navigation: FuseNavigation[] = [
	{
		id: 'jobs',
		title: 'Jobs Summary',
		type: 'item',
		icon: 'assignment',
		url: '/pages/jobs/list',
		permissions: ['admin', 'jobs-summary'],
	},
	{
		id: 'planner',
		title: 'Job Planner',
		type: 'item',
		icon: 'today',
		url: '/pages/planner/dashboard',
		permissions: ['admin'],
	},
	{
		id: 'gantt',
		title: 'Gantt Chart',
		type: 'item',
		icon: 'bar_chart',
		url: '/pages/planner/chart',
		permissions: ['admin'],
	},
	{
		id: 'completedjobs',
		title: 'Completed Jobs',
		type: 'item',
		icon: 'assignment_turned_in',
		url: '/pages/planner/completedjobs',
		permissions: ['admin'],
	},
	{
		id: 'clients',
		title: 'Customers',
		type: 'item',
		icon: 'how_to_reg',
		url: '/pages/clients/list',
		permissions: ['admin'],
	},
	{
		id: 'timeoff',
		title: 'Time-Off Planner',
		type: 'item',
		icon: 'today',
		url: '/pages/planner/timeoff',
		permissions: ['timeoff-planner'],
	},
	{
		id: 'crew',
		title: 'Crew',
		type: 'item',
		icon: 'group',
		url: '/pages/crew/list',
		permissions: ['payroll'],
	},
	{
		id: 'staff',
		title: 'Admins',
		type: 'item',
		icon: 'grade',
		url: '/pages/staff/list',
		permissions: ['admin'],
	},
	{
		id: 'payroll',
		title: 'Payroll',
		type: 'item',
		icon: 'attach_money',
		url: '/pages/payroll',
		permissions: ['payroll'],
	},
	{
		id: 'dashboard',
		title: 'Dashboard',
		type: 'item',
		icon: 'dashboard',
		url: '/pages/reports',
		permissions: ['admin'],
	},
	{
		id: 'recognition-calendar',
		title: 'Recognition Calendar',
		type: 'item',
		icon: 'assignment',
		url: '/pages/recognition-calendar',
		permissions: ['admin'],
	},
];
